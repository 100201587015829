.scale-to-ten {
  display: flex;
  align-items: center;
  height: 3.8rem;

  .dot {
    cursor: pointer;

    &.selected {
      min-width: 3.8rem;
      max-width: 3.8rem;
      min-height: 3.8rem;
      max-height: 3.8rem;
      border: 0.3rem solid white;
      box-shadow: 0 0 0.8rem rgba(0, 0, 0, 0.3);
      font-size: 2.4rem;
      font-weight: 500;
    }
  }

  div {
    text-align: center;
    color: white;
  }

  .link {
    border-bottom: 0.1rem solid lightgray;
    width: 100%;
    align-self: center;
  }

  input {
    height:0;
    width:0;
    padding:0;
    margin:0;
    z-index: -1;
    position: absolute;
  }
}

.form-container {
  h1 {
    padding-bottom: 35px;
    text-align: center;
  }
  .dot {
    min-width: 2rem;
    max-width: 2rem;
    min-height: 2rem;
    max-height: 2rem;
    border-radius: 50%;
    display: inline-block;
  }

  label {
    display: inline-block;
    margin-bottom: 15px;

    .dot {
      border: 0.5px solid black;

      div {
        text-align: center;
        font-size: 1rem;
        line-height: 2rem;
      }
    }
  }

  .form-group {
    margin-bottom: 30px !important;

    .required {
      color: red;
    }
  }
}
